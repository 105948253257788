<template>
  <div class="fill-height py-3 py-md-10">
    <v-container>
      <h1 class="cust-color">PRIVACY POLICY</h1>
      <div>
        <dl class="numbered-list">
          <dt>GENERAL INFORMATION</dt>
          <dd>
            Welcome to our Privacy Policy page! When you use our store services,
            you trust us with your information. This Privacy Policy is meant to
            help you understand what data we collect, why we collect it, and
            what we do with it. When you share information with us, we can make
            our services even better for you. For instance, we can show you more
            relevant search results and ads, help you connect with people or to
            make sharing with others quicker and easier. As you use our
            services, we want you to be clear how we’re using information and
            the ways in which you can protect your privacy. This is important;
            we hope you will take time to read it carefully. Remember, you can
            find controls to manage your information and protect your privacy
            and security. We’ve tried to keep it as simple as possible.
          </dd>
          <dt>
            RIGHT TO ACCESS, CORRECT AND DELETE DATA AND TO OBJECT TO DATA
            PROCESSING
          </dt>
          <dd>
            Our customers have the right to access, correct and delete personal
            data relating to them, and to object to the processing of such data,
            by addressing a written request, at any time. The Company makes
            every effort to put in place suitable precautions to safeguard the
            security and privacy of personal data, and to prevent it from being
            altered, corrupted, destroyed or accessed by unauthorized third
            parties. However, the Company does not control each and every risk
            related to the use of the Internet, and therefore warns the Site
            users of the potential risks involved in the functioning and use of
            the Internet. The Site may include links to other web sites or other
            internet sources. As the Company cannot control these web sites and
            external sources, the Company cannot be held responsible for the
            provision or display of these web sites and external sources, and
            may not be held liable for the content, advertising, products,
            services or any other material available on or from these web sites
            or external sources.
          </dd>
          <dt>MANAGEMENT OF PERSONAL DATA</dt>
          <dd>
            You can view or edit your personal data online for many of our
            services. You can also make choices about our collection and use of
            your data. How you can access or control your personal data will
            depend on which services you use. You can choose whether you wish to
            receive promotional communications from our store by email, SMS,
            physical mail, and telephone. If you receive promotional email or
            SMS messages from us and would like to opt out, you can do so by
            following the directions in that message. You can also make choices
            about the receipt of promotional email, telephone calls, and postal
            mail by visiting and signing into Company Promotional Communications
            Manager, which allows you to update contact information, manage
            contact preferences, opt out of email subscriptions, and choose
            whether to share your contact information with our partners. These
            choices do not apply to mandatory service communications that are
            part of certain store services.
          </dd>
          <dt>INFORMATION WE COLLECT</dt>
          <dd>
            Our store collects data to operate effectively and provide you the
            best experiences with our services. You provide some of this data
            directly, such as when you create a personal account. We get some of
            it by recording how you interact with our services by, for example,
            using technologies like cookies, and receiving error reports or
            usage data from software running on your device. We also obtain data
            from third parties (including other companies). For example, we
            supplement the data we collect by purchasing demographic data from
            other companies. We also use services from other companies to help
            us determine a location based on your IP address in order to
            customize certain services to your location. The data we collect
            depends on the services and features you use.
          </dd>
          <dt>HOW WE USE YOUR INFORMATION</dt>
          <dd>
            Our store uses the data we collect for three basic purposes: to
            operate our business and provide (including improving and
            personalizing) the services we offer, to send communications,
            including promotional communications, and to display advertising. In
            carrying out these purposes, we combine data we collect through the
            various store services you use to give you a more seamless,
            consistent and personalized experience. However, to enhance privacy,
            we have built in technological and procedural safeguards designed to
            prevent certain data combinations. For example, we store data we
            collect from you when you are unauthenticated (not signed in)
            separately from any account information that directly identifies
            you, such as your name, email address or phone number.
          </dd>
          <dt>SHARING YOUR INFORMATION</dt>
          <dd>
            We share your personal data with your consent or as necessary to
            complete any transaction or provide any service you have requested
            or authorized. For example, we share your content with third parties
            when you tell us to do so. When you provide payment data to make a
            purchase, we will share payment data with banks and other entities
            that process payment transactions or provide other financial
            services, and for fraud prevention and credit risk reduction. In
            addition, we share personal data among our controlled affiliates and
            subsidiaries. We also share personal data with vendors or agents
            working on our behalf for the purposes described in this statement.
            For example, companies we've hired to provide customer service
            support or assist in protecting and securing our systems and
            services may need access to personal data in order to provide those
            functions. In such cases, these companies must abide by our data
            privacy and security requirements and are not allowed to use
            personal data they receive from us for any other purpose. We may
            also disclose personal data as part of a corporate transaction such
            as a merger or sale of assets.
          </dd>
          <dt>CANCELLATION POLICY</dt>
          <dd>
            You can do the cancellation by yourself on the "Customer order"
            page, before the order moving to status "order confirmation". *
            Please note that the "Order cancellation request form" must be input
            and send back to us to specify of your refund information.
          </dd>
          <dt>RETURN POLICY</dt>
          <dd>
            Returned items must be new and unused, and in the same condition as
            they were sent in their original packaging. If the item you have
            received is damaged (damaged is cause of manufacturer and Webike
            Thailand only), please contact us by e-mail with a photo attached
            within 3 days so that we may remedy the situation. When you return,
            please use the carrier we specify.If you use different carriers from
            the one told from us, we may not accept to receive your return or
            may ask you to deposit import tax which we may need to pay to
            receive your returns. If you have any questions about returns,
            please e-mail to hellocst209@gmail.com * Please note "Receipt/Tax
            invoice/Delivery order" must be returned with the product in case of
            returnable or claiming order. *Refunding Amount The refunding amount
            varies due to the reason why you are returning the item. If it's our
            or the manufacturer's defect, we will make the full amount of what
            we received in Thai Baht. If it’s purchaser’s misunderstanding, then
            we will make the refund of purchased priced of the items. This
            returning policy is based on your credit card company or other
            payment service. Please understand that we are refunding the full
            amount of the item returned in Thai Baht and we are not responsible
            for money lost or gained due to the transaction. We will process to
            refund as same as your payment method to thaibikeparts.com *Shipping
            costs Shipping costs cannot be refunded unless the product was
            returned because of our error or damage. Who pays the postage on
            returned Items? If you return something due to it being unsuitable
            or not fitting correctly, you will need to pay the postage costs to
            return it to us. * Please note that if you wish to return the
            item(s) while the item(s) are in transit, we may deduct the return
            shipping fee from total refunding amount. We will process to refund
            as same as your payment method to thaibikeparts.com Your returned
            shipping costs are refunded if the item is being returned as a
            result of our error or damage.(damaged is cause of manufacturer and
            Thaibikebarts.com only
          </dd>
          <dt>SHIPPING POLICY</dt>
          <dd>
            Estimated Delivery: 15 – 20 days* (in regular seasons) or more
            depends on holiday seasons. *This doesn’t include our 1 day
            processing time. Our shipping methods being currently available is
            Thai Post Office or DHL Ecommerce. About Late Deliveries Our
            shipping partners do their best to ensure you receive your package
            on time, however occasional delays do occur. thaibikeparts.com
            cannot be held responsible for delays caused by unforeseen or
            uncontrollable events such as: *Possible Reasons for Delay Incorrect
            Address Missing apartment, building, floor, or unit number Severe
            weather conditions International customs procedures
          </dd>
        </dl>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: `Thai bike parts`,
    titleTemplate: 'Privacy Policy | %s'
  }
}
</script>

<style scoped>
</style>
